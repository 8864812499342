import React, { useState } from 'react';
import { Layout } from '../../components/layout/Layout';
import { Score } from '../../components/ui/Score';
import { Wallet, TrendingUp, Building, Briefcase, AlertCircle, CheckCircle } from 'lucide-react';
import { WealthLogForm } from '../../components/forms/WealthLogForm';
import { format } from 'date-fns';

const initialData = {
  overall: {
    value: 0,
    trend: 'neutral' as const,
    change: 0
  },
  income: {
    monthly: 0,
    expenses: 0,
    status: 'neutral' as const,
    difference: 0
  },
  netWorth: {
    value: 0,
    trend: 'neutral' as const,
    change: 0,
    total: 0,
    breakdown: {
      liquidSavings: 0,
      investments: 0,
      assets: 0,
      liabilities: 0
    }
  },
  skills: {
    value: 0,
    trend: 'neutral' as const,
    change: 0,
    current: {
      sales: 0,
      networking: 0,
      leadership: 0,
      vision: 0,
      communication: 0
    }
  }
};

const skillRecommendations = {
  sales: [
    { activity: 'Cold Email Challenge', type: 'Practice', duration: '30 days' },
    { activity: 'Sales Psychology Course', type: 'Course', duration: '8 weeks' }
  ],
  networking: [
    { activity: 'Industry Meetups', type: 'Practice', duration: 'Ongoing' },
    { activity: 'LinkedIn Networking Strategy', type: 'Course', duration: '4 weeks' }
  ],
  leadership: [
    { activity: 'Team Lead Shadow Program', type: 'Practice', duration: '3 months' },
    { activity: 'Strategic Leadership Course', type: 'Course', duration: '12 weeks' }
  ],
  vision: [
    { activity: 'Future Mapping Workshop', type: 'Workshop', duration: '2 days' },
    { activity: 'Strategic Planning Course', type: 'Course', duration: '6 weeks' }
  ],
  communication: [
    { activity: 'Public Speaking Club', type: 'Practice', duration: 'Ongoing' },
    { activity: 'Executive Communication Course', type: 'Course', duration: '8 weeks' }
  ]
};

export function WealthDashboard() {
  const [mockData] = useState(initialData);
  const [isLogFormOpen, setIsLogFormOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState<keyof typeof skillRecommendations>('sales');

  const handleLogSubmit = (data: any) => {
    console.log('Wealth log data:', data);
    setIsLogFormOpen(false);
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(amount);
  };

  return (
    <Layout>
      <div className="space-y-8">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">Wealth Dashboard</h1>
          <button
            onClick={() => setIsLogFormOpen(true)}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Log Transaction
          </button>
        </div>

        <div className="grid gap-6 md:grid-cols-3">
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-emerald-50 rounded-lg">
                <TrendingUp className="h-6 w-6 text-emerald-600" />
              </div>
              <h3 className="text-lg font-semibold">Monthly Status</h3>
            </div>
            <div className="space-y-4">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Income</span>
                <span className="font-medium">{formatCurrency(mockData.income.monthly)}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Expenses</span>
                <span className="font-medium">{formatCurrency(mockData.income.expenses)}</span>
              </div>
              <div className="pt-4 border-t">
                <div className="flex items-center justify-between">
                  <span className="text-sm font-medium">Status</span>
                  <div className={`flex items-center gap-1 ${
                    mockData.income.status === 'surplus' ? 'text-green-600' : 'text-gray-600'
                  }`}>
                    {mockData.income.status === 'surplus' ? (
                      <CheckCircle className="h-4 w-4" />
                    ) : (
                      <AlertCircle className="h-4 w-4" />
                    )}
                    <span className="font-medium capitalize">
                      {mockData.income.status} ({formatCurrency(Math.abs(mockData.income.difference))})
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-blue-50 rounded-lg">
                <Building className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-lg font-semibold">Net Worth</h3>
            </div>
            <Score score={mockData.netWorth} label="Net Worth Score" />
            <div className="mt-4 space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Liquid Savings</span>
                <span className="font-medium">{formatCurrency(mockData.netWorth.breakdown.liquidSavings)}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Investments</span>
                <span className="font-medium">{formatCurrency(mockData.netWorth.breakdown.investments)}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Assets</span>
                <span className="font-medium">{formatCurrency(mockData.netWorth.breakdown.assets)}</span>
              </div>
              <div className="flex justify-between text-sm text-red-600">
                <span>Liabilities</span>
                <span>{formatCurrency(mockData.netWorth.breakdown.liabilities)}</span>
              </div>
              <div className="pt-2 border-t">
                <div className="flex justify-between font-medium">
                  <span>Total</span>
                  <span>{formatCurrency(mockData.netWorth.total)}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-purple-50 rounded-lg">
                <Briefcase className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="text-lg font-semibold">High-Leverage Skills</h3>
            </div>
            <Score score={mockData.skills} label="Skills Score" />
            <div className="mt-4 space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Select Skill
                </label>
                <select
                  value={selectedSkill}
                  onChange={(e) => setSelectedSkill(e.target.value as keyof typeof skillRecommendations)}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                >
                  <option value="sales">Sales ({mockData.skills.current.sales}/10)</option>
                  <option value="networking">Networking ({mockData.skills.current.networking}/10)</option>
                  <option value="leadership">Leadership ({mockData.skills.current.leadership}/10)</option>
                  <option value="vision">Vision Setting ({mockData.skills.current.vision}/10)</option>
                  <option value="communication">Communication ({mockData.skills.current.communication}/10)</option>
                </select>
              </div>
              
              <div className="space-y-2">
                <p className="text-sm font-medium text-gray-700">Recommended Activities:</p>
                {skillRecommendations[selectedSkill].map((rec, index) => (
                  <div key={index} className="bg-gray-50 p-3 rounded-lg">
                    <div className="flex justify-between text-sm">
                      <span className="font-medium">{rec.activity}</span>
                      <span className="text-gray-500">{rec.duration}</span>
                    </div>
                    <span className="text-xs text-purple-600">{rec.type}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
          <h3 className="text-lg font-semibold mb-4">Recent Transactions</h3>
          <div className="text-center text-gray-500 py-8">
            No transactions recorded yet. Start tracking your wealth journey!
          </div>
        </div>

        {isLogFormOpen && (
          <WealthLogForm
            isOpen={isLogFormOpen}
            onClose={() => setIsLogFormOpen(false)}
            onSubmit={handleLogSubmit}
          />
        )}
      </div>
    </Layout>
  );
}