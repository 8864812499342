import React from 'react';
import { InsightCard } from './InsightCard';

const mockInsights = [
  {
    type: 'improvement' as const,
    area: 'health' as const,
    message: 'Your sleep quality has improved significantly this week. Keep maintaining your bedtime routine!',
    metric: {
      label: 'Sleep Score',
      value: 85,
      trend: 'up' as const,
      change: 12
    }
  },
  {
    type: 'warning' as const,
    area: 'wealth' as const,
    message: 'Your expenses have increased by 15% this month. Consider reviewing your budget.',
    metric: {
      label: 'Monthly Expenses',
      value: 2500,
      trend: 'up' as const,
      change: 15
    }
  },
  {
    type: 'achievement' as const,
    area: 'relationships' as const,
    message: 'You\'ve consistently maintained quality time with family this month!',
    metric: {
      label: 'Family Time',
      value: 12,
      trend: 'up' as const,
      change: 20
    }
  }
];

export function AIInsights() {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">AI Insights</h3>
      <div className="grid gap-4">
        {mockInsights.map((insight, index) => (
          <InsightCard key={index} insight={insight} />
        ))}
      </div>
    </div>
  );
}