import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { X, Mail } from 'lucide-react';

interface EmailCollectionPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export function EmailCollectionPopup({ isOpen, onClose }: EmailCollectionPopupProps) {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    try {
      const response = await fetch(`${import.meta.env.VITE_API_URL}/api/collect-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 429) {
          setError('All spots have been filled. Thank you for your interest!');
        } else {
          throw new Error(data.message || 'Failed to submit email');
        }
        return;
      }

      setSuccess(true);
      setTimeout(() => {
        onClose();
      }, 3000);
    } catch (err) {
      setError('Failed to submit email. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-md w-full bg-white rounded-xl shadow-lg">
          <div className="relative p-6">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
            >
              <X className="h-5 w-5" />
            </button>

            <div className="flex flex-col items-center text-center">
              <div className="p-3 bg-blue-100 rounded-full">
                <Mail className="h-6 w-6 text-blue-600" />
              </div>

              <Dialog.Title className="mt-4 text-xl font-semibold text-gray-900">
                Want Lifetime Access?
              </Dialog.Title>

              <Dialog.Description className="mt-2 text-sm text-gray-500">
                Help Flow become the best tool for life optimization by dropping your email below to share your feedback. Only 50 spots left, act now!
              </Dialog.Description>

              {!success ? (
                <form onSubmit={handleSubmit} className="mt-6 w-full">
                  <div className="space-y-4">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />

                    {error && (
                      <p className="text-sm text-red-600">{error}</p>
                    )}

                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 disabled:opacity-50"
                    >
                      {isSubmitting ? 'Submitting...' : 'Get Lifetime Access'}
                    </button>
                  </div>
                </form>
              ) : (
                <div className="mt-6 text-green-600">
                  Thank you! We'll be in touch soon.
                </div>
              )}
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}