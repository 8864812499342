import React from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';

interface ScoreProps {
  score: {
    value: number;
    trend: 'up' | 'down';
    change: number;
  };
  label: string;
}

export function Score({ score, label }: ScoreProps) {
  return (
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm text-gray-500">{label}</p>
        <p className="text-2xl font-bold">{score.value}%</p>
      </div>
      <div className={`flex items-center gap-1 ${score.trend === 'up' ? 'text-green-600' : 'text-red-600'}`}>
        {score.trend === 'up' ? <TrendingUp className="w-4 h-4" /> : <TrendingDown className="w-4 h-4" />}
        <span className="text-sm font-medium">{score.change}%</span>
      </div>
    </div>
  );
}