import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowRight, Heart, Brain, Wallet, CheckCircle2 } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { Logo } from '../components/Logo';
import { EmailCollectionPopup } from '../components/EmailCollectionPopup';

const features = [
  {
    icon: Heart,
    title: 'Optimize Health',
    description: 'Track sleep, nutrition, and fitness with AI-powered insights.',
  },
  {
    icon: Wallet,
    title: 'Build Wealth',
    description: 'Monitor finances, investments, and skill development.',
  },
  {
    icon: Brain,
    title: 'Strengthen Relationships',
    description: 'Nurture connections with family, friends, and community.',
  },
];

const benefits = [
  'Personalized AI coaching',
  'Data-driven insights',
  'Progress tracking',
  'Goal setting and achievement',
  'Integration with your favorite apps',
  '24/7 support',
];

export function Landing() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [showEmailPopup, setShowEmailPopup] = useState(false);

  useEffect(() => {
    // Show popup after 5 seconds
    const timer = setTimeout(() => {
      setShowEmailPopup(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const handleGetStarted = () => {
    if (user) {
      navigate('/dashboard');
    } else {
      navigate('/select-plan');
    }
  };

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <div className="flex justify-center mb-8">
              <Logo />
            </div>
            <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
              Life Optimization,{' '}
              <span className="text-blue-600">Simplified</span>
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
              Transform your life with Flow's AI-powered system for optimizing health, wealth, and relationships. Start your journey today.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={handleGetStarted}
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700"
              >
                Start 7-Day Free Trial
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <Link
                to="/login"
                className="inline-flex items-center justify-center px-6 py-3 border border-gray-300 text-base font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50"
              >
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="bg-gray-50 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-3 gap-12">
            {features.map((feature) => {
              const Icon = feature.icon;
              return (
                <div
                  key={feature.title}
                  className="text-center"
                >
                  <div className="flex justify-center mb-4">
                    <div className="p-3 bg-blue-100 rounded-lg">
                      <Icon className="h-8 w-8 text-blue-600" />
                    </div>
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                  <p className="text-gray-600">{feature.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Everything You Need to Thrive</h2>
            <p className="text-xl text-gray-600">
              Unlock your full potential with our comprehensive suite of tools
            </p>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {benefits.map((benefit) => (
              <div
                key={benefit}
                className="flex items-center gap-3 p-4 bg-white rounded-lg shadow-sm"
              >
                <CheckCircle2 className="h-5 w-5 text-blue-600 flex-shrink-0" />
                <span className="text-gray-700">{benefit}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-blue-600 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-8">
            Start Your Journey to a Better Life
          </h2>
          <button
            onClick={handleGetStarted}
            className="inline-flex items-center justify-center px-8 py-4 border border-transparent text-lg font-medium rounded-lg text-blue-600 bg-white hover:bg-blue-50"
          >
            Try Flow Free for 7 Days
          </button>
          <p className="mt-4 text-blue-100">
            Then $10/month or $100/year (save 17%)
          </p>
        </div>
      </div>

      <EmailCollectionPopup 
        isOpen={showEmailPopup} 
        onClose={() => setShowEmailPopup(false)} 
      />
    </div>
  );
}