import { useState } from 'react';
import { plaidService } from '../services/plaid';

export function usePlaid() {
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  async function connectBank(userId: string) {
    setIsLoading(true);
    try {
      const token = await plaidService.createLinkToken(userId);
      // In a real implementation, this would open Plaid Link
      setIsConnected(true);
      return token;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isConnected,
    isLoading,
    error,
    connectBank,
  };
}