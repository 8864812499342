import React from 'react';
import { Layout } from '../../components/layout/Layout';
import { IntegrationsPanel } from '../../components/integrations/IntegrationsPanel';

export function Integrations() {
  return (
    <Layout>
      <div className="max-w-4xl mx-auto">
        <IntegrationsPanel />
      </div>
    </Layout>
  );
}