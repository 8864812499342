import React from 'react';
import { Calendar, Check, X, RefreshCw } from 'lucide-react';
import { useRelationships } from '../../hooks/useRelationships';

export function CalendarIntegrationPanel() {
  const { events, isLoading, error, syncCalendarEvents } = useRelationships();
  const [lastSync, setLastSync] = React.useState<Date | null>(null);

  const handleSync = async () => {
    try {
      await syncCalendarEvents();
      setLastSync(new Date());
    } catch (err) {
      console.error('Error syncing calendar:', err);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-purple-50 rounded-lg">
          <Calendar className="h-5 w-5 text-purple-600" />
        </div>
        <h3 className="text-lg font-medium">Calendar Integration</h3>
      </div>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="font-medium">Google Calendar</span>
            {events.length > 0 ? (
              <span className="flex items-center gap-1 text-sm text-green-600">
                <Check className="h-4 w-4" />
                Connected
              </span>
            ) : (
              <span className="flex items-center gap-1 text-sm text-red-600">
                <X className="h-4 w-4" />
                Not Connected
              </span>
            )}
          </div>

          {events.length > 0 && (
            <button
              onClick={handleSync}
              disabled={isLoading}
              className="flex items-center gap-2 px-3 py-2 text-sm font-medium rounded-md text-purple-600 bg-purple-50 hover:bg-purple-100"
            >
              <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
              Sync Now
            </button>
          )}
        </div>

        {events.length === 0 && (
          <button
            onClick={handleSync}
            disabled={isLoading}
            className="w-full flex justify-center items-center gap-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></span>
                Connecting...
              </>
            ) : (
              <>
                <Calendar className="h-4 w-4" />
                Connect Google Calendar
              </>
            )}
          </button>
        )}

        {lastSync && (
          <p className="text-sm text-gray-500">
            Last synced: {lastSync.toLocaleString()}
          </p>
        )}

        {error && (
          <div className="p-3 bg-red-50 text-red-700 text-sm rounded-md">
            {error.message}
          </div>
        )}

        {events.length > 0 && (
          <div className="border-t pt-4">
            <h4 className="text-sm font-medium text-gray-900 mb-2">
              Recent Events ({events.length})
            </h4>
            <div className="space-y-2">
              {events.slice(0, 3).map((event: any) => (
                <div key={event.id} className="text-sm text-gray-600">
                  {event.title} - {new Date(event.start).toLocaleDateString()}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}