import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Shield, CreditCard, ArrowLeft } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { stripeService } from '../services/stripe';

// Initialize Stripe
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

const plans = {
  monthly: {
    name: 'Monthly',
    price: '$10',
    period: '/month',
    amount: 1000, // Amount in cents
    priceId: 'price_1QI0WO2NU0aEmh45IL50NkSX'
  },
  annual: {
    name: 'Annual',
    price: '$100',
    period: '/year',
    amount: 10000, // Amount in cents
    priceId: 'price_1QI0XK2NU0aEmh45A3xNhG4T'
  }
};

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const { planId } = useParams<{ planId: keyof typeof plans }>();
  const selectedPlan = planId ? plans[planId] : null;

  useEffect(() => {
    if (!planId || !user) return;

    const initializePayment = async () => {
      try {
        const { clientSecret: secret } = await stripeService.createSubscription(planId);
        setClientSecret(secret);
      } catch (err) {
        setError('Failed to initialize payment. Please try again.');
      }
    };

    initializePayment();
  }, [planId, user]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements || !user || !selectedPlan || !clientSecret) {
      return;
    }

    setIsProcessing(true);

    try {
      const { error: submitError } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/dashboard`,
          payment_method_data: {
            billing_details: {
              email: user.email,
              name: user.name,
            },
          },
        },
      });

      if (submitError) {
        setError(submitError.message ?? 'An error occurred');
        setIsProcessing(false);
      } else {
        navigate('/dashboard');
      }
    } catch (err) {
      setError('An unexpected error occurred');
      setIsProcessing(false);
    }
  };

  if (!selectedPlan) {
    return <div>Invalid plan selected</div>;
  }

  if (!clientSecret) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto px-4">
      <button
        onClick={() => navigate('/select-plan')}
        className="flex items-center text-gray-600 hover:text-gray-900 mb-8"
      >
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to Plans
      </button>

      <div className="mb-8 text-center">
        <div className="flex justify-center mb-4">
          <div className="p-3 bg-blue-100 rounded-full">
            <CreditCard className="h-6 w-6 text-blue-600" />
          </div>
        </div>
        <h1 className="text-3xl font-bold text-gray-900 mb-2">
          Complete Your Trial Setup
        </h1>
        <p className="text-gray-600">
          Your card won't be charged until after your 7-day free trial.
        </p>
      </div>

      <div className="bg-white rounded-xl border border-gray-200 p-6">
        <div className="mb-6 pb-6 border-b">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="font-medium">Selected Plan:</h3>
              <p className="text-gray-600">{selectedPlan.name} Plan</p>
            </div>
            <div className="text-right">
              <p className="font-medium">{selectedPlan.price}{selectedPlan.period}</p>
              <p className="text-sm text-green-600">7-day free trial</p>
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <h3 className="text-lg font-medium mb-4">Payment Details</h3>
            <div className="bg-gray-50 rounded-lg p-4">
              <PaymentElement />
            </div>
          </div>
          
          {error && (
            <div className="p-3 bg-red-50 text-red-700 rounded-md">
              {error}
            </div>
          )}

          <button
            type="submit"
            disabled={!stripe || isProcessing}
            className="w-full flex items-center justify-center px-4 py-3 border border-transparent text-sm font-medium rounded-lg text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50"
          >
            {isProcessing ? (
              <>
                <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></span>
                Processing...
              </>
            ) : (
              'Start Free Trial'
            )}
          </button>

          <div className="text-center">
            <p className="text-sm text-gray-500">
              By starting your trial, you agree to our Terms of Service and Privacy Policy.
            </p>
          </div>
        </form>
      </div>

      <div className="mt-6 space-y-4">
        <div className="flex items-start gap-4 bg-gray-50 rounded-lg p-4">
          <Shield className="h-5 w-5 text-gray-400 flex-shrink-0 mt-0.5" />
          <div className="text-sm text-gray-500">
            <p className="font-medium text-gray-700">Secure Payment</p>
            <p>
              Your payment information is encrypted and secure. We never store your
              credit card details.
            </p>
          </div>
        </div>

        <div className="bg-blue-50 rounded-lg p-4">
          <p className="text-sm text-blue-700">
            <strong>Remember:</strong> You won't be charged during your 7-day trial. 
            You can cancel anytime before the trial ends.
          </p>
        </div>
      </div>
    </div>
  );
}

export function PaymentPage() {
  const { planId } = useParams<{ planId: keyof typeof plans }>();
  const selectedPlan = planId ? plans[planId] : null;
  const [options, setOptions] = useState({});

  useEffect(() => {
    if (!selectedPlan) return;

    setOptions({
      mode: 'subscription',
      amount: selectedPlan.amount,
      currency: 'usd',
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#2563eb',
          colorBackground: '#ffffff',
          colorText: '#1f2937',
          colorDanger: '#dc2626',
          fontFamily: 'Inter, system-ui, sans-serif',
          spacingUnit: '4px',
          borderRadius: '8px',
        },
        rules: {
          '.Input': {
            border: '1px solid #e5e7eb',
            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            padding: '8px 12px',
          },
          '.Input:focus': {
            border: '1px solid #3b82f6',
            boxShadow: '0 0 0 1px #3b82f6',
          },
          '.Label': {
            fontWeight: '500',
            color: '#374151',
          },
        },
      },
      payment_method_types: ['card'],
    });
  }, [selectedPlan]);

  if (!selectedPlan) {
    return <div>Invalid plan selected</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
    </div>
  );
}