import React from 'react';
import { Dialog } from '@headlessui/react';
import { Users, Home, Globe, X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const relationshipsLogSchema = z.object({
  type: z.enum(['family', 'friends', 'community']),
  activity: z.string().min(1),
  duration: z.number().min(0),
  quality: z.number().min(1).max(10),
  notes: z.string().optional(),
  date: z.string(),
});

type RelationshipsLogFormData = z.infer<typeof relationshipsLogSchema>;

interface RelationshipsLogFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: RelationshipsLogFormData) => void;
}

export function RelationshipsLogForm({ isOpen, onClose, onSubmit }: RelationshipsLogFormProps) {
  const { register, handleSubmit, formState: { errors } } = useForm<RelationshipsLogFormData>({
    resolver: zodResolver(relationshipsLogSchema),
    defaultValues: {
      type: 'family',
      activity: '',
      duration: 60,
      quality: 8,
      date: new Date().toISOString().split('T')[0],
    },
  });

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-2xl w-full bg-white rounded-xl shadow-lg">
          <div className="flex items-center justify-between p-6 border-b">
            <Dialog.Title className="text-xl font-semibold">Log Interaction</Dialog.Title>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-8">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Type</label>
                <div className="mt-2 grid grid-cols-3 gap-3">
                  <label className="relative flex">
                    <input
                      type="radio"
                      value="family"
                      {...register('type')}
                      className="peer sr-only"
                    />
                    <div className="w-full flex items-center justify-center gap-2 p-3 text-gray-600 bg-white border rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 peer-checked:bg-blue-50 hover:bg-gray-50">
                      <Home className="h-5 w-5" />
                      <span>Family</span>
                    </div>
                  </label>
                  <label className="relative flex">
                    <input
                      type="radio"
                      value="friends"
                      {...register('type')}
                      className="peer sr-only"
                    />
                    <div className="w-full flex items-center justify-center gap-2 p-3 text-gray-600 bg-white border rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 peer-checked:bg-blue-50 hover:bg-gray-50">
                      <Users className="h-5 w-5" />
                      <span>Friends</span>
                    </div>
                  </label>
                  <label className="relative flex">
                    <input
                      type="radio"
                      value="community"
                      {...register('type')}
                      className="peer sr-only"
                    />
                    <div className="w-full flex items-center justify-center gap-2 p-3 text-gray-600 bg-white border rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 peer-checked:bg-blue-50 hover:bg-gray-50">
                      <Globe className="h-5 w-5" />
                      <span>Community</span>
                    </div>
                  </label>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Activity</label>
                <input
                  type="text"
                  {...register('activity')}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="e.g., Family dinner, Coffee with friends"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Date</label>
                  <input
                    type="date"
                    {...register('date')}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Duration (minutes)</label>
                  <input
                    type="number"
                    {...register('duration', { valueAsNumber: true })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Quality (1-10)</label>
                <input
                  type="range"
                  min="1"
                  max="10"
                  {...register('quality', { valueAsNumber: true })}
                  className="mt-2 w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                />
                <div className="mt-1 flex justify-between text-xs text-gray-500">
                  <span>Poor</span>
                  <span>Excellent</span>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Notes</label>
                <textarea
                  {...register('notes')}
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  placeholder="Any thoughts or reflections about this interaction..."
                />
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Save Interaction
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}