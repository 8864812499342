import React, { useState, useRef, useEffect } from 'react';
import { Send, Bot, Sparkles } from 'lucide-react';

interface Message {
  id: string;
  type: 'user' | 'ai';
  content: string;
  timestamp: Date;
}

interface AICoachProps {
  userId: string;
}

export function AICoach({ userId }: AICoachProps) {
  const [messages, setMessages] = useState<Message[]>([
    {
      id: '1',
      type: 'ai',
      content: "Hi! I'm your Flow AI Coach. I've noticed you're making great progress! Your sleep quality is up 12% this week, and you've maintained consistent family interactions. How can I help you optimize further today?",
      timestamp: new Date()
    }
  ]);
  const [input, setInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage: Message = {
      id: Date.now().toString(),
      type: 'user',
      content: input,
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsTyping(true);

    // Simulate AI response
    setTimeout(() => {
      const aiResponse: Message = {
        id: (Date.now() + 1).toString(),
        content: generateAIResponse(input),
        type: 'ai',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, aiResponse]);
      setIsTyping(false);
    }, 1500);
  };

  const generateAIResponse = (userInput: string): string => {
    const responses = [
      "I see your sleep metrics are trending positively! Your average of 7.5 hours is great, and your sleep quality score has improved by 12%. To optimize further, try reducing screen time 30 minutes before bed. Would you like more sleep optimization tips?",
      
      "Great progress on your savings! You're 15% ahead of your monthly goal. I notice you've reduced discretionary spending by 20%. One opportunity: consider automating an additional 5% to your investment accounts to accelerate wealth building. Would you like to explore this strategy?",
      
      "Your relationship metrics are impressive! You've had 8 meaningful family interactions this month, up 30% from last month. To build on this momentum, consider scheduling a weekly family activity. Your quality scores are highest during morning interactions - shall we plan around that?",
      
      "Looking at your health data, your workout consistency is excellent at 4 sessions per week! Your intensity scores suggest you're ready to increase challenge levels. Based on your recovery metrics, Tuesday and Thursday would be optimal for higher-intensity sessions. Would you like a personalized progression plan?",
      
      "I'm noticing strong integration between your wealth and skills development. Your investment in online courses correlates with a 25% increase in your income potential. There's an opportunity to leverage this further in your current field. Would you like to explore high-impact skill areas?",
      
      "Your community engagement shows a positive trend! The quality scores of your recent interactions are averaging 8.5/10. Building on this, there's a great opportunity to deepen these connections through shared interests. I see potential synergies with your fitness goals - would you like to explore community fitness events?"
    ];

    // In a real implementation, we would analyze the user's input and metrics
    // to provide truly personalized responses
    return responses[Math.floor(Math.random() * responses.length)];
  };

  return (
    <div className="flex flex-col h-[600px] bg-white rounded-xl shadow-sm border border-gray-200">
      <div className="flex items-center justify-between p-4 border-b">
        <div className="flex items-center gap-2">
          <Bot className="h-6 w-6 text-blue-600" />
          <h2 className="text-lg font-semibold">AI Coach</h2>
        </div>
        <div className="flex items-center gap-2">
          <Sparkles className="h-5 w-5 text-blue-600" />
          <span className="text-sm text-gray-500">Powered by Flow AI</span>
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-[80%] rounded-lg p-3 ${
                message.type === 'user'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-100 text-gray-900'
              }`}
            >
              <p className="text-sm">{message.content}</p>
              <span className="text-xs opacity-70 mt-1 block">
                {message.timestamp.toLocaleTimeString()}
              </span>
            </div>
          </div>
        ))}
        {isTyping && (
          <div className="flex justify-start">
            <div className="bg-gray-100 rounded-lg p-3">
              <div className="flex space-x-2">
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" />
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }} />
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }} />
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleSubmit} className="p-4 border-t">
        <div className="flex gap-2">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask your AI coach anything..."
            className="flex-1 rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            type="submit"
            disabled={!input.trim()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Send className="h-5 w-5" />
          </button>
        </div>
      </form>
    </div>
  );
}