import { HealthMetrics, WealthMetrics, RelationshipMetrics } from '../types';

interface Score {
  value: number;
  trend: 'up' | 'down' | 'neutral';
  change: number;
}

interface DomainScores {
  health: Score;
  wealth: Score;
  relationships: Score;
  overall: Score;
}

export class ScoringService {
  private static instance: ScoringService;
  private previousScores: DomainScores | null = null;

  private constructor() {}

  static getInstance(): ScoringService {
    if (!ScoringService.instance) {
      ScoringService.instance = new ScoringService();
    }
    return ScoringService.instance;
  }

  calculateHealthScore(metrics: HealthMetrics): number {
    if (!metrics) return 0;

    // Sleep score (33%)
    const sleepScore = metrics.sleep ? (
      (metrics.sleep.hours / 8) * 100 * 0.5 + // Hours of sleep (target: 8 hours)
      (metrics.sleep.quality / 10) * 100 * 0.5 // Sleep quality
    ) * 0.33 : 0;

    // Nutrition score (33%)
    const nutritionScore = metrics.nutrition ? (
      (Math.min(metrics.nutrition.calories / 2000, 1) * 100 * 0.4) + // Calorie target (2000 cal)
      (Math.min(metrics.nutrition.protein / 150, 1) * 100 * 0.4) + // Protein target (150g)
      (Math.min(metrics.nutrition.water / 2.5, 1) * 100 * 0.2) // Water target (2.5L)
    ) * 0.33 : 0;

    // Training score (34%)
    const trainingScore = metrics.training ? (
      (Math.min(metrics.training.duration / 60, 1) * 100 * 0.5) + // Duration target (60 min)
      (metrics.training.intensity / 10) * 100 * 0.5 // Training intensity
    ) * 0.34 : 0;

    return Math.round(sleepScore + nutritionScore + trainingScore);
  }

  calculateWealthScore(metrics: WealthMetrics): number {
    if (!metrics) return 0;

    // Income/Expenses score (33%)
    const incomeRatio = metrics.income.amount / Math.max(metrics.expenses.amount, 1);
    const financialScore = Math.min(incomeRatio, 2) * 50 * 0.33; // Target: 2x income/expenses ratio

    // Net Worth score (33%)
    const netWorth = metrics.investments.amount;
    const netWorthScore = Math.min(netWorth / 100000, 1) * 100 * 0.33; // Target: $100k net worth

    // Skills score (34%)
    const skillScore = (metrics.skills.proficiency / 10) * 100 * 0.34;

    return Math.round(financialScore + netWorthScore + skillScore);
  }

  calculateRelationshipsScore(metrics: RelationshipMetrics[]): number {
    if (!metrics || metrics.length === 0) return 0;

    const familyInteractions = metrics.filter(m => m.type === 'family');
    const friendInteractions = metrics.filter(m => m.type === 'friends');
    const communityInteractions = metrics.filter(m => m.type === 'community');

    // Family score (33%)
    const familyScore = familyInteractions.length > 0 ? (
      (Math.min(familyInteractions.length / 4, 1) * 100 * 0.5) + // Target: 4 interactions per week
      (familyInteractions.reduce((acc, curr) => acc + curr.quality, 0) / familyInteractions.length / 10 * 100 * 0.5)
    ) * 0.33 : 0;

    // Friends score (33%)
    const friendScore = friendInteractions.length > 0 ? (
      (Math.min(friendInteractions.length / 3, 1) * 100 * 0.5) + // Target: 3 interactions per week
      (friendInteractions.reduce((acc, curr) => acc + curr.quality, 0) / friendInteractions.length / 10 * 100 * 0.5)
    ) * 0.33 : 0;

    // Community score (34%)
    const communityScore = communityInteractions.length > 0 ? (
      (Math.min(communityInteractions.length / 2, 1) * 100 * 0.5) + // Target: 2 community engagements per week
      (communityInteractions.reduce((acc, curr) => acc + curr.quality, 0) / communityInteractions.length / 10 * 100 * 0.5)
    ) * 0.34 : 0;

    return Math.round(familyScore + friendScore + communityScore);
  }

  calculateOverallScore(healthScore: number, wealthScore: number, relationshipsScore: number): number {
    const weights = {
      health: 1,
      wealth: 1,
      relationships: 1
    };

    const totalWeight = weights.health + weights.wealth + weights.relationships;

    return Math.round(
      (healthScore * weights.health +
       wealthScore * weights.wealth +
       relationshipsScore * weights.relationships) / totalWeight
    );
  }

  calculateTrend(currentScore: number, previousScore: number): { trend: 'up' | 'down' | 'neutral', change: number } {
    if (!previousScore) return { trend: 'neutral', change: 0 };

    const change = Math.round(((currentScore - previousScore) / previousScore) * 100);
    return {
      trend: change > 0 ? 'up' : change < 0 ? 'down' : 'neutral',
      change: Math.abs(change)
    };
  }

  calculateAllScores(
    healthMetrics: HealthMetrics,
    wealthMetrics: WealthMetrics,
    relationshipMetrics: RelationshipMetrics[]
  ): DomainScores {
    const healthScore = this.calculateHealthScore(healthMetrics);
    const wealthScore = this.calculateWealthScore(wealthMetrics);
    const relationshipsScore = this.calculateRelationshipsScore(relationshipMetrics);
    const overallScore = this.calculateOverallScore(healthScore, wealthScore, relationshipsScore);

    const scores: DomainScores = {
      health: {
        value: healthScore,
        ...this.calculateTrend(healthScore, this.previousScores?.health.value || 0)
      },
      wealth: {
        value: wealthScore,
        ...this.calculateTrend(wealthScore, this.previousScores?.wealth.value || 0)
      },
      relationships: {
        value: relationshipsScore,
        ...this.calculateTrend(relationshipsScore, this.previousScores?.relationships.value || 0)
      },
      overall: {
        value: overallScore,
        ...this.calculateTrend(overallScore, this.previousScores?.overall.value || 0)
      }
    };

    this.previousScores = scores;
    return scores;
  }
}