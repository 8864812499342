import React from 'react';
import { Wallet, Check, X, Building } from 'lucide-react';
import { usePlaid } from '../../hooks/usePlaid';
import { useAuth } from '../../contexts/AuthContext';

export function PlaidIntegrationPanel() {
  const { user } = useAuth();
  const { isConnected, isLoading, error, connectBank } = usePlaid();

  const handleConnect = async () => {
    if (!user) return;
    try {
      await connectBank(user.id);
    } catch (err) {
      console.error('Error connecting bank:', err);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-green-50 rounded-lg">
          <Building className="h-5 w-5 text-green-600" />
        </div>
        <h3 className="text-lg font-medium">Financial Accounts</h3>
      </div>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="font-medium">Bank Connection</span>
            {isConnected ? (
              <span className="flex items-center gap-1 text-sm text-green-600">
                <Check className="h-4 w-4" />
                Connected
              </span>
            ) : (
              <span className="flex items-center gap-1 text-sm text-red-600">
                <X className="h-4 w-4" />
                Not Connected
              </span>
            )}
          </div>
        </div>

        {!isConnected && (
          <button
            onClick={handleConnect}
            disabled={isLoading}
            className="w-full flex justify-center items-center gap-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50"
          >
            {isLoading ? (
              <>
                <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></span>
                Connecting...
              </>
            ) : (
              <>
                <Wallet className="h-4 w-4" />
                Connect Bank Account
              </>
            )}
          </button>
        )}

        {error && (
          <div className="p-3 bg-red-50 text-red-700 text-sm rounded-md">
            {error.message}
          </div>
        )}

        <div className="border-t pt-4">
          <h4 className="text-sm font-medium text-gray-900 mb-2">Supported Banks</h4>
          <div className="grid grid-cols-2 gap-2 text-sm text-gray-600">
            <div>• Chase</div>
            <div>• Bank of America</div>
            <div>• Wells Fargo</div>
            <div>• Citibank</div>
            <div>• Capital One</div>
            <div>• And many more...</div>
          </div>
        </div>
      </div>
    </div>
  );
}