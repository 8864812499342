import { useState, useEffect } from 'react';
import { relationshipsService } from '../services/relationships';

export function useRelationships() {
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  async function syncCalendarEvents() {
    setIsLoading(true);
    try {
      const syncedEvents = await relationshipsService.syncCalendarEvents();
      setEvents(syncedEvents);
      return syncedEvents;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }

  async function logInteraction(interaction: any) {
    try {
      const newInteraction = await relationshipsService.addInteraction(interaction);
      return newInteraction;
    } catch (err) {
      setError(err as Error);
      throw err;
    }
  }

  return {
    events,
    isLoading,
    error,
    syncCalendarEvents,
    logInteraction,
  };
}