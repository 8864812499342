import React from 'react';
import { Dialog } from '@headlessui/react';
import { Moon, X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const sleepLogSchema = z.object({
  hours: z.number().min(0).max(24),
  quality: z.number().min(1).max(10),
  notes: z.string().optional(),
  date: z.string(),
});

type SleepLogFormData = z.infer<typeof sleepLogSchema>;

interface SleepLogFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: SleepLogFormData) => void;
}

export function SleepLogForm({ isOpen, onClose, onSubmit }: SleepLogFormProps) {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<SleepLogFormData>({
    resolver: zodResolver(sleepLogSchema),
    defaultValues: {
      hours: 7,
      quality: 7,
      date: new Date().toISOString().split('T')[0],
    },
  });

  const qualityValue = watch('quality');

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-md w-full bg-white rounded-xl shadow-lg">
          <div className="flex items-center justify-between p-6 border-b">
            <div className="flex items-center gap-2">
              <Moon className="h-5 w-5 text-blue-600" />
              <Dialog.Title className="text-xl font-semibold">Log Sleep</Dialog.Title>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Date</label>
              <input
                type="date"
                {...register('date')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Hours of Sleep</label>
              <input
                type="number"
                step="0.5"
                {...register('hours', { valueAsNumber: true })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
              {errors.hours && (
                <p className="mt-1 text-sm text-red-600">{errors.hours.message}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Sleep Quality (1-10): {qualityValue}
              </label>
              <input
                type="range"
                min="1"
                max="10"
                {...register('quality', { valueAsNumber: true })}
                className="mt-2 w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              <div className="mt-1 flex justify-between text-xs text-gray-500">
                <span>Poor</span>
                <span>Excellent</span>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Notes</label>
              <textarea
                {...register('notes')}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Any factors affecting your sleep..."
              />
            </div>

            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
              >
                Save Sleep Log
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}