import React from 'react';
import { motion } from 'framer-motion';

export function Logo() {
  const waveVariants = {
    animate: {
      y: [0, -4, 0],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  const staggerDelay = 0.2;

  return (
    <div className="relative w-12 h-12">
      {[0, 1, 2].map((index) => (
        <motion.div
          key={index}
          className="absolute w-full h-2 bg-blue-600 rounded-full"
          style={{ top: `${index * 12 + 6}px` }}
          variants={waveVariants}
          animate="animate"
          transition={{
            delay: index * staggerDelay,
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
      ))}
    </div>
  );
}