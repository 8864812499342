import React from 'react';
import { Dialog } from '@headlessui/react';
import { Dumbbell, X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const trainingLogSchema = z.object({
  type: z.string().min(1, 'Training type is required'),
  duration: z.number().min(0),
  intensity: z.number().min(1).max(10),
  notes: z.string().optional(),
  date: z.string(),
});

type TrainingLogFormData = z.infer<typeof trainingLogSchema>;

interface TrainingLogFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: TrainingLogFormData) => void;
}

export function TrainingLogForm({ isOpen, onClose, onSubmit }: TrainingLogFormProps) {
  const { register, handleSubmit, watch, formState: { errors } } = useForm<TrainingLogFormData>({
    resolver: zodResolver(trainingLogSchema),
    defaultValues: {
      type: 'strength',
      duration: 60,
      intensity: 7,
      date: new Date().toISOString().split('T')[0],
    },
  });

  const intensityValue = watch('intensity');

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-md w-full bg-white rounded-xl shadow-lg">
          <div className="flex items-center justify-between p-6 border-b">
            <div className="flex items-center gap-2">
              <Dumbbell className="h-5 w-5 text-red-600" />
              <Dialog.Title className="text-xl font-semibold">Log Training</Dialog.Title>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Date</label>
              <input
                type="date"
                {...register('date')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Training Type</label>
              <select
                {...register('type')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="strength">Strength Training</option>
                <option value="cardio">Cardio</option>
                <option value="hiit">HIIT</option>
                <option value="yoga">Yoga</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Duration (minutes)</label>
              <input
                type="number"
                {...register('duration', { valueAsNumber: true })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Intensity (1-10): {intensityValue}
              </label>
              <input
                type="range"
                min="1"
                max="10"
                {...register('intensity', { valueAsNumber: true })}
                className="mt-2 w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              <div className="mt-1 flex justify-between text-xs text-gray-500">
                <span>Light</span>
                <span>Maximum</span>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Notes</label>
              <textarea
                {...register('notes')}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                placeholder="Exercise details, how you felt, etc..."
              />
            </div>

            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
              >
                Save Training Log
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}