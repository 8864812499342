import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield, Check, Star, Clock, CreditCard } from 'lucide-react';
import { addDays, format } from 'date-fns';

const plans = [
  {
    id: 'monthly',
    name: 'Monthly Plan',
    price: '$10',
    period: '/month',
    description: 'Perfect for getting started with Flow',
    features: [
      'All Flow features',
      'AI coaching',
      'Unlimited tracking',
      'Priority support',
      'All integrations'
    ],
    priceId: 'price_1QI0WO2NU0aEmh45IL50NkSX'
  },
  {
    id: 'annual',
    name: 'Annual Plan',
    price: '$100',
    period: '/year',
    description: 'Best value for committed users',
    features: [
      'All Flow features',
      'AI coaching',
      'Unlimited tracking',
      'Priority support',
      'All integrations',
      '2 months free'
    ],
    featured: true,
    savings: 'Save 17%',
    priceId: 'price_1QI0XK2NU0aEmh45A3xNhG4T'
  }
];

export function PlanSelection() {
  const navigate = useNavigate();
  const trialEndDate = addDays(new Date(), 7);
  const formattedTrialEndDate = format(trialEndDate, 'MMMM d, yyyy');

  const handlePlanSelect = (planId: string) => {
    navigate(`/payment/${planId}`);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <div className="mb-8 text-center">
          <div className="flex justify-center mb-4">
            <div className="p-3 bg-blue-100 rounded-full">
              <CreditCard className="h-6 w-6 text-blue-600" />
            </div>
          </div>
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            Choose Your Plan
          </h1>
          <p className="text-gray-600">
            Start your 7-day free trial. First payment on {formattedTrialEndDate}
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {plans.map((plan) => (
            <div
              key={plan.id}
              className={`relative rounded-xl border-2 ${
                plan.featured
                  ? 'border-blue-600 shadow-xl'
                  : 'border-gray-200'
              } bg-white p-6`}
            >
              {plan.featured && (
                <div className="absolute -top-3 left-0 right-0 mx-auto w-32 rounded-full bg-blue-600 px-3 py-1 text-center text-sm font-medium text-white">
                  Best Value
                </div>
              )}

              <div className="text-center">
                <h3 className="text-lg font-semibold">{plan.name}</h3>
                <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
                <div className="mt-4">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {plan.price}
                  </span>
                  <span className="text-base font-medium text-gray-500">
                    {plan.period}
                  </span>
                </div>
                {plan.savings && (
                  <span className="mt-1 text-sm text-green-600 font-medium">
                    {plan.savings}
                  </span>
                )}
              </div>

              <ul className="mt-6 space-y-4">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex items-start">
                    <Check className="h-5 w-5 text-green-500 flex-shrink-0 mr-2" />
                    <span className="text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                onClick={() => handlePlanSelect(plan.id)}
                className={`mt-8 w-full rounded-lg px-4 py-3 text-center text-sm font-semibold transition-all ${
                  plan.featured
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'bg-gray-50 text-gray-900 hover:bg-gray-100'
                }`}
              >
                Start 7-Day Free Trial
              </button>
            </div>
          ))}
        </div>

        <div className="mt-12 grid grid-cols-3 gap-4 text-center text-sm text-gray-500">
          <div className="flex flex-col items-center">
            <Clock className="h-5 w-5 text-blue-600 mb-2" />
            7-day free trial
          </div>
          <div className="flex flex-col items-center">
            <Star className="h-5 w-5 text-blue-600 mb-2" />
            Cancel anytime
          </div>
          <div className="flex flex-col items-center">
            <Shield className="h-5 w-5 text-blue-600 mb-2" />
            Secure payment
          </div>
        </div>
      </div>
    </div>
  );
}