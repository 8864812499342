import React from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameMonth, isToday, isSameDay } from 'date-fns';
import { ChevronLeft, ChevronRight, CheckCircle, X } from 'lucide-react';

interface Event {
  id: string;
  title: string;
  date: Date;
  type: 'family' | 'friends' | 'community';
  completed?: boolean;
  cancelled?: boolean;
}

interface CalendarProps {
  events: Event[];
  onAddEvent: () => void;
  onEventComplete: (eventId: string) => void;
  onEventCancel: (event: Event) => void;
}

export function Calendar({ events, onAddEvent, onEventComplete, onEventCancel }: CalendarProps) {
  const [currentMonth, setCurrentMonth] = React.useState(new Date());
  const firstDayOfMonth = startOfMonth(currentMonth);
  const lastDayOfMonth = endOfMonth(currentMonth);
  const days = eachDayOfInterval({ start: firstDayOfMonth, end: lastDayOfMonth });

  const nextMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1));
  };

  const prevMonth = () => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1));
  };

  const getEventsForDay = (date: Date) => {
    return events.filter(event => isSameDay(event.date, date));
  };

  const getEventClassName = (event: Event) => {
    const baseClasses = 'text-xs rounded px-2 py-1 flex items-center justify-between gap-2';
    if (event.cancelled) {
      return `${baseClasses} bg-gray-100 text-gray-500 line-through`;
    }
    switch (event.type) {
      case 'family':
        return `${baseClasses} bg-red-100 text-red-800`;
      case 'friends':
        return `${baseClasses} bg-blue-100 text-blue-800`;
      case 'community':
        return `${baseClasses} bg-purple-100 text-purple-800`;
      default:
        return baseClasses;
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-semibold text-gray-900">
          {format(currentMonth, 'MMMM yyyy')}
        </h2>
        <div className="flex items-center gap-4">
          <button
            onClick={prevMonth}
            className="p-2 text-gray-400 hover:text-gray-500"
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          <button
            onClick={nextMonth}
            className="p-2 text-gray-400 hover:text-gray-500"
          >
            <ChevronRight className="h-5 w-5" />
          </button>
          <button
            onClick={onAddEvent}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
          >
            Add Event
          </button>
        </div>
      </div>

      <div className="grid grid-cols-7 gap-px bg-gray-200 rounded-lg overflow-hidden">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div
            key={day}
            className="bg-gray-50 py-2 text-center text-sm font-medium text-gray-500"
          >
            {day}
          </div>
        ))}

        {days.map((day, dayIdx) => {
          const dayEvents = getEventsForDay(day);
          return (
            <div
              key={day.toString()}
              className={`
                min-h-[100px] bg-white p-2
                ${!isSameMonth(day, currentMonth) ? 'text-gray-400' : ''}
                ${isToday(day) ? 'bg-blue-50' : ''}
              `}
            >
              <div className="font-medium text-sm">{format(day, 'd')}</div>
              <div className="mt-1 space-y-1">
                {dayEvents.map((event) => (
                  <div key={event.id} className={getEventClassName(event)}>
                    <span className="truncate flex-1">{event.title}</span>
                    {!event.completed && !event.cancelled && (
                      <div className="flex items-center gap-1">
                        <button
                          onClick={() => onEventComplete(event.id)}
                          className="text-green-600 hover:text-green-700"
                        >
                          <CheckCircle className="h-4 w-4" />
                        </button>
                        <button
                          onClick={() => onEventCancel(event)}
                          className="text-red-600 hover:text-red-700"
                        >
                          <X className="h-4 w-4" />
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}