import { useState, useEffect } from 'react';
import { healthKitService } from '../services/healthkit';

export function useHealthKit() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [healthData, setHealthData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    initializeHealthKit();
  }, []);

  async function initializeHealthKit() {
    try {
      const authorized = await healthKitService.initialize();
      setIsAuthorized(authorized);
    } catch (err) {
      setError(err as Error);
    }
  }

  async function fetchHealthData(startDate: Date, endDate: Date) {
    if (!isAuthorized) {
      throw new Error('HealthKit not authorized');
    }

    setIsLoading(true);
    try {
      const data = await healthKitService.fetchHealthData(startDate, endDate);
      setHealthData(data);
      return data;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isAuthorized,
    healthData,
    isLoading,
    error,
    fetchHealthData,
  };
}