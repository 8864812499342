import React, { useState } from 'react';
import { Activity, Check, X, RefreshCw } from 'lucide-react';
import { useHealthKit } from '../../hooks/useHealthKit';

export function HealthIntegrationPanel() {
  const { isAuthorized, isLoading, error, fetchHealthData } = useHealthKit();
  const [lastSync, setLastSync] = useState<Date | null>(null);

  const handleSync = async () => {
    try {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      await fetchHealthData(startDate, endDate);
      setLastSync(new Date());
    } catch (err) {
      console.error('Error syncing health data:', err);
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-blue-50 rounded-lg">
          <Activity className="h-5 w-5 text-blue-600" />
        </div>
        <h3 className="text-lg font-medium">Health Data Integration</h3>
      </div>

      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span className="font-medium">Apple HealthKit</span>
            {isAuthorized ? (
              <span className="flex items-center gap-1 text-sm text-green-600">
                <Check className="h-4 w-4" />
                Connected
              </span>
            ) : (
              <span className="flex items-center gap-1 text-sm text-red-600">
                <X className="h-4 w-4" />
                Not Connected
              </span>
            )}
          </div>

          {isAuthorized && (
            <button
              onClick={handleSync}
              disabled={isLoading}
              className="flex items-center gap-2 px-3 py-2 text-sm font-medium rounded-md text-blue-600 bg-blue-50 hover:bg-blue-100"
            >
              <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
              Sync Now
            </button>
          )}
        </div>

        {!isAuthorized && (
          <button
            onClick={() => {}} // This will be handled by the native app
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
          >
            Connect Apple Health
          </button>
        )}

        {lastSync && (
          <p className="text-sm text-gray-500">
            Last synced: {lastSync.toLocaleString()}
          </p>
        )}

        {error && (
          <div className="p-3 bg-red-50 text-red-700 text-sm rounded-md">
            {error.message}
          </div>
        )}
      </div>
    </div>
  );
}