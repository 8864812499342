import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase';

export async function sendWelcomeEmail(email: string, name: string): Promise<void> {
  try {
    const sendWelcomeEmailFunction = httpsCallable(functions, 'sendWelcomeEmail');
    await sendWelcomeEmailFunction({ email, name });
  } catch (error) {
    console.error('Error sending welcome email:', error);
    // Don't throw the error as this is a non-critical operation
    // The user can still proceed with using the app
  }
}