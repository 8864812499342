import React from 'react';
import { Quote } from 'lucide-react';

interface DailyQuoteProps {
  quote: string;
  author: string;
}

export function DailyQuote({ quote, author }: DailyQuoteProps) {
  return (
    <div className="bg-gradient-to-br from-blue-50 to-white p-6 rounded-xl shadow-sm border border-blue-100">
      <div className="flex items-start gap-4">
        <div className="p-2 bg-blue-100 rounded-full">
          <Quote className="h-5 w-5 text-blue-600" />
        </div>
        <div>
          <p className="text-gray-800 font-medium italic">"{quote}"</p>
          <p className="mt-2 text-sm text-gray-600">— {author}</p>
        </div>
      </div>
    </div>
  );
}