import React, { useState, useEffect } from 'react';
import { Compass, Heart, Wallet, Brain, MessageSquare } from 'lucide-react';

interface OnboardingTutorialProps {
  isOpen: boolean;
  onClose: () => void;
}

const tutorialSteps = [
  {
    title: "Welcome to Flow",
    description: "Let's get you started on your journey to optimal living.",
    icon: <Compass className="w-8 h-8 text-blue-500" />,
    highlightId: "dashboard-overview"
  },
  {
    title: "Health Dashboard",
    description: "Track your sleep, nutrition, and fitness progress.",
    icon: <Heart className="w-8 h-8 text-blue-500" />,
    highlightId: "health-section"
  },
  {
    title: "Wealth Management",
    description: "Monitor your finances, investments, and skill development.",
    icon: <Wallet className="w-8 h-8 text-blue-500" />,
    highlightId: "wealth-section"
  },
  {
    title: "Relationships",
    description: "Strengthen connections with family, friends, and community.",
    icon: <Brain className="w-8 h-8 text-blue-500" />,
    highlightId: "relationships-section"
  },
  {
    title: "AI Coach",
    description: "Get personalized insights and recommendations.",
    icon: <MessageSquare className="w-8 h-8 text-blue-500" />,
    highlightId: "ai-coach-section"
  }
];

export function OnboardingTutorial({ isOpen, onClose }: OnboardingTutorialProps) {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (isOpen) {
      // Remove previous highlights
      document.querySelectorAll('.tutorial-highlight').forEach(el => {
        el.classList.remove('tutorial-highlight');
      });

      // Add highlight to current section
      const elementToHighlight = document.getElementById(tutorialSteps[currentStep].highlightId);
      if (elementToHighlight) {
        elementToHighlight.classList.add('tutorial-highlight');
      }
    }
  }, [currentStep, isOpen]);

  if (!isOpen) return null;

  const handleNext = () => {
    if (currentStep < tutorialSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      // Remove highlights before closing
      document.querySelectorAll('.tutorial-highlight').forEach(el => {
        el.classList.remove('tutorial-highlight');
      });
      onClose();
    }
  };

  const currentTutorialStep = tutorialSteps[currentStep];

  return (
    <div className="fixed inset-0 z-50">
      <div className="absolute inset-0 bg-black bg-opacity-50" />
      
      <div className="absolute inset-0 pointer-events-none">
        <style>
          {`
            .tutorial-highlight {
              box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
              border-radius: 8px;
              position: relative;
              z-index: 1;
            }
          `}
        </style>
      </div>

      <div className="relative z-50 flex items-center justify-center min-h-screen p-4">
        <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
          <div className="flex flex-col items-center text-center">
            {currentTutorialStep.icon}
            <h2 className="text-2xl font-bold mt-4 text-gray-900">
              {currentTutorialStep.title}
            </h2>
            <p className="mt-2 text-gray-600">
              {currentTutorialStep.description}
            </p>
          </div>

          <div className="mt-8 flex justify-between items-center">
            <div className="flex space-x-2">
              {tutorialSteps.map((_, index) => (
                <div
                  key={index}
                  className={`h-2 w-2 rounded-full ${
                    index === currentStep ? 'bg-blue-500' : 'bg-gray-300'
                  }`}
                />
              ))}
            </div>
            <button
              onClick={handleNext}
              className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              {currentStep === tutorialSteps.length - 1 ? 'Get Started' : 'Next'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}