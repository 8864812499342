import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyA8g74f2DZSKxi4HmPdHfXoiC8G8Ix4bso",
  authDomain: "flow-4e35c.firebaseapp.com",
  projectId: "flow-4e35c",
  storageBucket: "flow-4e35c.appspot.com",
  messagingSenderId: "944356595003",
  appId: "1:944356595003:web:9b0dd465eda3672bc1131e"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const functions = getFunctions(app);
export const googleProvider = new GoogleAuthProvider();