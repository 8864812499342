import React from 'react';
import { HealthIntegrationPanel } from './HealthIntegrationPanel';
import { PlaidIntegrationPanel } from './PlaidIntegrationPanel';
import { CalendarIntegrationPanel } from './CalendarIntegrationPanel';

export function IntegrationsPanel() {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900">Integrations</h1>
      </div>

      <div className="grid gap-6">
        <HealthIntegrationPanel />
        <PlaidIntegrationPanel />
        <CalendarIntegrationPanel />
      </div>

      <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
        <p className="text-sm text-blue-700">
          Connect your favorite apps and services to Flow for a more comprehensive view of your life optimization journey.
        </p>
      </div>
    </div>
  );
}