import React from 'react';
import { Lightbulb, TrendingUp, TrendingDown } from 'lucide-react';

interface Insight {
  type: 'improvement' | 'warning' | 'achievement';
  area: 'health' | 'wealth' | 'relationships';
  message: string;
  metric?: {
    label: string;
    value: number;
    trend: 'up' | 'down';
    change: number;
  };
}

interface InsightCardProps {
  insight: Insight;
}

export function InsightCard({ insight }: InsightCardProps) {
  const getBackgroundColor = () => {
    switch (insight.type) {
      case 'improvement':
        return 'bg-blue-50';
      case 'warning':
        return 'bg-amber-50';
      case 'achievement':
        return 'bg-green-50';
    }
  };

  const getIconColor = () => {
    switch (insight.type) {
      case 'improvement':
        return 'text-blue-600';
      case 'warning':
        return 'text-amber-600';
      case 'achievement':
        return 'text-green-600';
    }
  };

  return (
    <div className={`rounded-lg p-4 ${getBackgroundColor()}`}>
      <div className="flex items-start gap-4">
        <div className={`p-2 rounded-full bg-white ${getIconColor()}`}>
          <Lightbulb className="h-5 w-5" />
        </div>
        <div className="flex-1">
          <h4 className="text-sm font-medium text-gray-900 capitalize">
            {insight.area} Insight
          </h4>
          <p className="mt-1 text-sm text-gray-600">{insight.message}</p>
          {insight.metric && (
            <div className="mt-3 flex items-center gap-2">
              <span className="text-sm text-gray-600">{insight.metric.label}:</span>
              <span className="font-medium">{insight.metric.value}</span>
              <div className={`flex items-center gap-1 ${
                insight.metric.trend === 'up' ? 'text-green-600' : 'text-red-600'
              }`}>
                {insight.metric.trend === 'up' ? (
                  <TrendingUp className="h-4 w-4" />
                ) : (
                  <TrendingDown className="h-4 w-4" />
                )}
                <span className="text-sm">{insight.metric.change}%</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}