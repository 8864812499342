import React from 'react';
import { Brain, Heart, Wallet } from 'lucide-react';
import { Link } from 'react-router-dom';
import type { Score } from '../../types';

interface AreaCardProps {
  area: 'health' | 'wealth' | 'relationships';
  score: Score;
}

export function AreaCard({ area, score }: AreaCardProps) {
  const getIcon = () => {
    switch (area) {
      case 'health':
        return <Heart className="h-6 w-6 text-blue-500" />;
      case 'wealth':
        return <Wallet className="h-6 w-6 text-blue-500" />;
      case 'relationships':
        return <Brain className="h-6 w-6 text-blue-500" />;
    }
  };

  const getGradient = () => {
    return 'from-blue-500/10 to-transparent';
  };

  return (
    <Link to={`/${area}`}>
      <div className={`relative overflow-hidden rounded-xl border border-gray-200 bg-gradient-to-br ${getGradient()} p-6 hover:shadow-md transition-shadow cursor-pointer`}>
        <div className="flex items-center gap-4">
          <div className="rounded-full bg-white p-3 shadow-sm">
            {getIcon()}
          </div>
          <div>
            <h3 className="text-lg font-semibold capitalize text-gray-900">
              {area}
            </h3>
            <div className="mt-1 flex items-baseline gap-2">
              <span className="text-2xl font-bold text-gray-900">
                {score.value}
              </span>
              <span className={`text-sm ${
                score.trend === 'up' 
                  ? 'text-blue-500' 
                  : score.trend === 'down' 
                    ? 'text-red-500' 
                    : 'text-gray-500'
              }`}>
                {score.change}%
              </span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}