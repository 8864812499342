import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { Landing } from './pages/Landing';
import { Login } from './pages/auth/Login';
import { Register } from './pages/auth/Register';
import { Dashboard } from './pages/Dashboard';
import { PlanSelection } from './pages/PlanSelection';
import { PaymentPage } from './pages/PaymentPage';
import { HealthDashboard } from './pages/areas/HealthDashboard';
import { WealthDashboard } from './pages/areas/WealthDashboard';
import { RelationshipsDashboard } from './pages/areas/RelationshipsDashboard';
import { Feedback } from './pages/Feedback';
import { Integrations } from './pages/settings/Integrations';
import { PrivateRoute } from './components/PrivateRoute';

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/select-plan" element={
            <PrivateRoute>
              <PlanSelection />
            </PrivateRoute>
          } />
          <Route path="/payment/:planId" element={
            <PrivateRoute>
              <PaymentPage />
            </PrivateRoute>
          } />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />
          <Route path="/health" element={
            <PrivateRoute>
              <HealthDashboard />
            </PrivateRoute>
          } />
          <Route path="/wealth" element={
            <PrivateRoute>
              <WealthDashboard />
            </PrivateRoute>
          } />
          <Route path="/relationships" element={
            <PrivateRoute>
              <RelationshipsDashboard />
            </PrivateRoute>
          } />
          <Route path="/feedback" element={
            <PrivateRoute>
              <Feedback />
            </PrivateRoute>
          } />
          <Route path="/integrations" element={
            <PrivateRoute>
              <Integrations />
            </PrivateRoute>
          } />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}