export const dailyQuotes = [
  {
    quote: "The only way to do great work is to love what you do.",
    author: "Steve Jobs",
    day: 1
  },
  {
    quote: "Success is not final, failure is not fatal: it is the courage to continue that counts.",
    author: "Winston Churchill",
    day: 2
  },
  {
    quote: "Your time is limited, don't waste it living someone else's life.",
    author: "Steve Jobs",
    day: 3
  },
  {
    quote: "The future belongs to those who believe in the beauty of their dreams.",
    author: "Eleanor Roosevelt",
    day: 4
  },
  {
    quote: "The best way to predict the future is to create it.",
    author: "Peter Drucker",
    day: 5
  },
  // Add more quotes for all 365 days...
];

export function getQuoteForDay(): { quote: string; author: string } {
  const startOfYear = new Date(new Date().getFullYear(), 0, 1);
  const today = new Date();
  const dayOfYear = Math.floor((today.getTime() - startOfYear.getTime()) / (24 * 60 * 60 * 1000)) + 1;
  const quoteIndex = (dayOfYear - 1) % dailyQuotes.length;
  return dailyQuotes[quoteIndex];
}