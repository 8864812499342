import { useState, useEffect } from 'react';
import { ScoringService } from '../services/scoring';
import type { HealthMetrics, WealthMetrics, RelationshipMetrics } from '../types';

export function useScoring() {
  const [scores, setScores] = useState({
    health: { value: 0, trend: 'neutral' as const, change: 0 },
    wealth: { value: 0, trend: 'neutral' as const, change: 0 },
    relationships: { value: 0, trend: 'neutral' as const, change: 0 },
    overall: { value: 0, trend: 'neutral' as const, change: 0 }
  });

  const scoringService = ScoringService.getInstance();

  const updateScores = (
    healthMetrics: HealthMetrics,
    wealthMetrics: WealthMetrics,
    relationshipMetrics: RelationshipMetrics[]
  ) => {
    const newScores = scoringService.calculateAllScores(
      healthMetrics,
      wealthMetrics,
      relationshipMetrics
    );
    setScores(newScores);
  };

  return {
    scores,
    updateScores
  };
}