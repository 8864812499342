import React from 'react';
import { Dialog } from '@headlessui/react';
import { Apple, X, Plus, Trash2 } from 'lucide-react';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const mealSchema = z.object({
  name: z.string().min(1, 'Meal name is required'),
  type: z.enum(['breakfast', 'lunch', 'dinner', 'snack']),
  calories: z.number().min(0),
});

const nutritionLogSchema = z.object({
  calories: z.number().min(0),
  protein: z.number().min(0),
  water: z.number().min(0),
  meals: z.array(mealSchema).optional(),
  date: z.string(),
});

type NutritionLogFormData = z.infer<typeof nutritionLogSchema>;

interface NutritionLogFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: NutritionLogFormData) => void;
}

export function NutritionLogForm({ isOpen, onClose, onSubmit }: NutritionLogFormProps) {
  const { register, control, handleSubmit, formState: { errors } } = useForm<NutritionLogFormData>({
    resolver: zodResolver(nutritionLogSchema),
    defaultValues: {
      calories: 0,
      protein: 0,
      water: 0,
      meals: [],
      date: new Date().toISOString().split('T')[0],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'meals',
  });

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-2xl w-full bg-white rounded-xl shadow-lg">
          <div className="flex items-center justify-between p-6 border-b">
            <div className="flex items-center gap-2">
              <Apple className="h-5 w-5 text-green-600" />
              <Dialog.Title className="text-xl font-semibold">Log Nutrition</Dialog.Title>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">Date</label>
              <input
                type="date"
                {...register('date')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            <div className="grid grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Total Calories</label>
                <input
                  type="number"
                  {...register('calories', { valueAsNumber: true })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Protein (g)</label>
                <input
                  type="number"
                  {...register('protein', { valueAsNumber: true })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Water (L)</label>
                <input
                  type="number"
                  step="0.1"
                  {...register('water', { valueAsNumber: true })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-sm font-medium text-gray-700">Meals</h3>
                <button
                  type="button"
                  onClick={() => append({ name: '', type: 'breakfast', calories: 0 })}
                  className="flex items-center gap-1 text-sm text-green-600 hover:text-green-700"
                >
                  <Plus className="h-4 w-4" />
                  Add Meal
                </button>
              </div>

              <div className="space-y-4">
                {fields.map((field, index) => (
                  <div key={field.id} className="flex items-start gap-4">
                    <div className="flex-1 grid grid-cols-3 gap-4">
                      <div>
                        <input
                          {...register(`meals.${index}.name`)}
                          placeholder="Meal name"
                          className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                      </div>
                      <div>
                        <select
                          {...register(`meals.${index}.type`)}
                          className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        >
                          <option value="breakfast">Breakfast</option>
                          <option value="lunch">Lunch</option>
                          <option value="dinner">Dinner</option>
                          <option value="snack">Snack</option>
                        </select>
                      </div>
                      <div>
                        <input
                          type="number"
                          {...register(`meals.${index}.calories`, { valueAsNumber: true })}
                          placeholder="Calories"
                          className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                    <button
                      type="button"
                      onClick={() => remove(index)}
                      className="text-red-500 hover:text-red-600"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700"
              >
                Save Nutrition Log
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}