import React, { useState, useEffect } from 'react';
import { Header } from '../components/layout/Header';
import { Sidebar } from '../components/layout/Sidebar';
import { MobileSidebar } from '../components/layout/MobileSidebar';
import { AreaCard } from '../components/dashboard/AreaCard';
import { ScoreCard } from '../components/dashboard/ScoreCard';
import { AICoach } from '../components/ai/AICoach';
import { AIInsights } from '../components/ai/AIInsights';
import { DailyQuote } from '../components/quotes/DailyQuote';
import { OnboardingTutorial } from '../components/onboarding/OnboardingTutorial';
import { useOnboarding } from '../hooks/useOnboarding';
import { useScoring } from '../hooks/useScoring';
import { getQuoteForDay } from '../data/quotes';
import { useAuth } from '../contexts/AuthContext';

export function Dashboard() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [dailyQuote, setDailyQuote] = useState({ quote: '', author: '' });
  const { showOnboarding, completeOnboarding } = useOnboarding();
  const { user } = useAuth();
  const { scores } = useScoring();

  useEffect(() => {
    const todaysQuote = getQuoteForDay();
    setDailyQuote(todaysQuote);
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <Header onMenuClick={() => setIsMobileMenuOpen(true)} />
      
      <div className="flex">
        <Sidebar />
        
        <main className="flex-1 p-6 lg:pl-64">
          <DailyQuote quote={dailyQuote.quote} author={dailyQuote.author} />
          
          <div className="mt-8">
            <ScoreCard
              title="Overall Optimal Score"
              score={scores.overall}
              description="Your combined score across health, wealth, and relationships"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
            <AreaCard area="health" score={scores.health} />
            <AreaCard area="wealth" score={scores.wealth} />
            <AreaCard area="relationships" score={scores.relationships} />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-8">
            <AIInsights />
            <AICoach userId={user?.id || 'demo'} />
          </div>
        </main>
      </div>

      <MobileSidebar 
        isOpen={isMobileMenuOpen} 
        onClose={() => setIsMobileMenuOpen(false)} 
      />
      
      {showOnboarding && (
        <OnboardingTutorial 
          isOpen={showOnboarding} 
          onClose={completeOnboarding}
        />
      )}
    </div>
  );
}