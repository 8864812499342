import React, { useState } from 'react';
import { Layout } from '../../components/layout/Layout';
import { Score } from '../../components/ui/Score';
import { Heart, Moon, Apple, Dumbbell } from 'lucide-react';
import { SleepLogForm } from '../../components/forms/health/SleepLogForm';
import { NutritionLogForm } from '../../components/forms/health/NutritionLogForm';
import { TrainingLogForm } from '../../components/forms/health/TrainingLogForm';
import { format } from 'date-fns';

interface ActivityLog {
  id: string;
  type: 'sleep' | 'nutrition' | 'training';
  timestamp: Date;
  data: any;
}

// Initialize all scores at 0 for new users
const initialData = {
  overall: {
    value: 0,
    trend: 'neutral' as const,
    change: 0
  },
  sleep: {
    value: 0,
    trend: 'neutral' as const,
    change: 0,
    hours: 0,
    quality: 'Not tracked'
  },
  nutrition: {
    value: 0,
    trend: 'neutral' as const,
    change: 0,
    calories: 0,
    protein: 0,
    water: 0
  },
  training: {
    value: 0,
    trend: 'neutral' as const,
    change: 0,
    workouts: 0,
    minutes: 0
  }
};

export function HealthDashboard() {
  const [mockData] = useState(initialData);
  const [isSleepFormOpen, setIsSleepFormOpen] = useState(false);
  const [isNutritionFormOpen, setIsNutritionFormOpen] = useState(false);
  const [isTrainingFormOpen, setIsTrainingFormOpen] = useState(false);
  const [activityLogs, setActivityLogs] = useState<ActivityLog[]>([]);

  // Rest of the component remains the same...
  // Previous implementation of handleLogSubmit and other functions
  const handleLogSubmit = (type: 'sleep' | 'nutrition' | 'training', data: any) => {
    const newLog: ActivityLog = {
      id: Date.now().toString(),
      type,
      timestamp: new Date(),
      data
    };
    setActivityLogs([newLog, ...activityLogs]);
    
    switch (type) {
      case 'sleep':
        setIsSleepFormOpen(false);
        break;
      case 'nutrition':
        setIsNutritionFormOpen(false);
        break;
      case 'training':
        setIsTrainingFormOpen(false);
        break;
    }
  };

  return (
    <Layout>
      <div className="space-y-8">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">Health Dashboard</h1>
          <div className="flex gap-3">
            <button
              onClick={() => setIsSleepFormOpen(true)}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Log Sleep
            </button>
            <button
              onClick={() => setIsNutritionFormOpen(true)}
              className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
            >
              Log Nutrition
            </button>
            <button
              onClick={() => setIsTrainingFormOpen(true)}
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
            >
              Log Training
            </button>
          </div>
        </div>

        <div className="grid gap-6 md:grid-cols-3">
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-blue-50 rounded-lg">
                <Moon className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-lg font-semibold">Sleep</h3>
            </div>
            <Score score={mockData.sleep} label="Sleep Score" />
            <div className="mt-4 space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Hours</span>
                <span className="font-medium">{mockData.sleep.hours}h</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Quality</span>
                <span className="font-medium">{mockData.sleep.quality}</span>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-green-50 rounded-lg">
                <Apple className="h-6 w-6 text-green-600" />
              </div>
              <h3 className="text-lg font-semibold">Nutrition</h3>
            </div>
            <Score score={mockData.nutrition} label="Nutrition Score" />
            <div className="mt-4 space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Calories</span>
                <span className="font-medium">{mockData.nutrition.calories}kcal</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Protein</span>
                <span className="font-medium">{mockData.nutrition.protein}g</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Water</span>
                <span className="font-medium">{mockData.nutrition.water}L</span>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-red-50 rounded-lg">
                <Dumbbell className="h-6 w-6 text-red-600" />
              </div>
              <h3 className="text-lg font-semibold">Training</h3>
            </div>
            <Score score={mockData.training} label="Training Score" />
            <div className="mt-4 space-y-2">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Workouts</span>
                <span className="font-medium">{mockData.training.workouts}/week</span>
              </div>
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Duration</span>
                <span className="font-medium">{mockData.training.minutes}min</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
          <h3 className="text-lg font-semibold mb-4">Recent Activities</h3>
          {activityLogs.length === 0 ? (
            <p className="text-gray-500 text-center py-4">No activities logged yet. Start tracking your health journey!</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Details</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date & Time</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {activityLogs.map((log) => (
                    <tr key={log.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="capitalize">{log.type}</span>
                      </td>
                      <td className="px-6 py-4">
                        {JSON.stringify(log.data)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {format(log.timestamp, 'MMM d, yyyy HH:mm')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <SleepLogForm
          isOpen={isSleepFormOpen}
          onClose={() => setIsSleepFormOpen(false)}
          onSubmit={(data) => handleLogSubmit('sleep', data)}
        />

        <NutritionLogForm
          isOpen={isNutritionFormOpen}
          onClose={() => setIsNutritionFormOpen(false)}
          onSubmit={(data) => handleLogSubmit('nutrition', data)}
        />

        <TrainingLogForm
          isOpen={isTrainingFormOpen}
          onClose={() => setIsTrainingFormOpen(false)}
          onSubmit={(data) => handleLogSubmit('training', data)}
        />
      </div>
    </Layout>
  );
}