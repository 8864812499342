import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

interface CreatePaymentIntentResponse {
  clientSecret: string;
  subscriptionId: string;
}

class StripeService {
  private async fetchWithAuth(endpoint: string, options: RequestInit = {}) {
    const response = await fetch(`${import.meta.env.VITE_API_URL}/api/stripe${endpoint}`, {
      ...options,
      headers: {
        ...options.headers,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return response.json();
  }

  async createSubscription(planId: string): Promise<CreatePaymentIntentResponse> {
    return this.fetchWithAuth('/create-subscription', {
      method: 'POST',
      body: JSON.stringify({ planId }),
    });
  }

  async getPublicKey(): Promise<string> {
    const response = await this.fetchWithAuth('/config');
    return response.publishableKey;
  }
}

export const stripeService = new StripeService();