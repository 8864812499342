import React, { useState } from 'react';
import { Layout } from '../../components/layout/Layout';
import { Score } from '../../components/ui/Score';
import { Heart, Users, Home, Globe } from 'lucide-react';
import { Calendar } from '../../components/calendar/Calendar';
import { RelationshipsLogForm } from '../../components/forms/RelationshipsLogForm';
import { AddEventForm } from '../../components/forms/AddEventForm';
import { format } from 'date-fns';

interface Event {
  id: string;
  title: string;
  date: Date;
  type: 'family' | 'friends' | 'community';
  completed?: boolean;
  cancelled?: boolean;
}

interface Interaction {
  id: string;
  type: 'family' | 'friends' | 'community';
  activity: string;
  quality: number;
  date: Date;
  notes?: string;
}

const initialData = {
  overall: {
    value: 0,
    trend: 'neutral' as const,
    change: 0
  },
  family: {
    value: 0,
    trend: 'neutral' as const,
    change: 0,
    interactions: 0
  },
  friends: {
    value: 0,
    trend: 'neutral' as const,
    change: 0,
    interactions: 0
  },
  community: {
    value: 0,
    trend: 'neutral' as const,
    change: 0,
    interactions: 0
  }
};

export function RelationshipsDashboard() {
  const [mockData] = useState(initialData);
  const [isLogFormOpen, setIsLogFormOpen] = useState(false);
  const [isEventFormOpen, setIsEventFormOpen] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [interactions, setInteractions] = useState<Interaction[]>([]);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);

  const handleLogSubmit = (data: any) => {
    const newInteraction: Interaction = {
      id: Date.now().toString(),
      ...data,
      date: new Date()
    };
    setInteractions([newInteraction, ...interactions]);
    setIsLogFormOpen(false);
  };

  const handleEventSubmit = (data: any) => {
    const newEvent: Event = {
      id: Date.now().toString(),
      title: data.title,
      date: new Date(data.date + 'T' + data.time),
      type: data.type
    };
    setEvents([...events, newEvent]);
    setIsEventFormOpen(false);
  };

  const handleEventComplete = (eventId: string) => {
    setEvents(events.map(event => 
      event.id === eventId ? { ...event, completed: true } : event
    ));
  };

  const handleEventCancel = (event: Event) => {
    setSelectedEvent(event);
    setIsCancelModalOpen(true);
  };

  const confirmEventCancel = () => {
    if (selectedEvent) {
      setEvents(events.map(event => 
        event.id === selectedEvent.id ? { ...event, cancelled: true } : event
      ));
      setIsCancelModalOpen(false);
      setSelectedEvent(null);
    }
  };

  return (
    <Layout>
      <div className="space-y-8">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">Relationships Dashboard</h1>
          <button
            onClick={() => setIsLogFormOpen(true)}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Log Interaction
          </button>
        </div>

        <div className="grid gap-6 md:grid-cols-3">
          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-red-50 rounded-lg">
                <Home className="h-6 w-6 text-red-600" />
              </div>
              <h3 className="text-lg font-semibold">Family</h3>
            </div>
            <Score score={mockData.family} label="Family Score" />
            <div className="mt-4">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Interactions</span>
                <span className="font-medium">{mockData.family.interactions} this month</span>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-blue-50 rounded-lg">
                <Users className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-lg font-semibold">Friends</h3>
            </div>
            <Score score={mockData.friends} label="Friends Score" />
            <div className="mt-4">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Interactions</span>
                <span className="font-medium">{mockData.friends.interactions} this month</span>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-2 bg-purple-50 rounded-lg">
                <Globe className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="text-lg font-semibold">Community</h3>
            </div>
            <Score score={mockData.community} label="Community Score" />
            <div className="mt-4">
              <div className="flex justify-between text-sm">
                <span className="text-gray-500">Interactions</span>
                <span className="font-medium">{mockData.community.interactions} this month</span>
              </div>
            </div>
          </div>
        </div>

        <Calendar 
          events={events}
          onAddEvent={() => setIsEventFormOpen(true)}
          onEventComplete={handleEventComplete}
          onEventCancel={handleEventCancel}
        />

        <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-200">
          <h3 className="text-lg font-semibold mb-4">Recent Interactions</h3>
          {interactions.length === 0 ? (
            <p className="text-gray-500 text-center py-4">No interactions logged yet. Start building your relationships!</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Type
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Activity
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Quality
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {interactions.map((interaction) => (
                    <tr key={interaction.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="capitalize">{interaction.type}</span>
                      </td>
                      <td className="px-6 py-4">
                        {interaction.activity}
                      </td>
                      <td className="px-6 py-4">
                        {interaction.quality}/10
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {format(interaction.date, 'MMM d, yyyy HH:mm')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {isLogFormOpen && (
          <RelationshipsLogForm
            isOpen={isLogFormOpen}
            onClose={() => setIsLogFormOpen(false)}
            onSubmit={handleLogSubmit}
          />
        )}

        {isEventFormOpen && (
          <AddEventForm
            isOpen={isEventFormOpen}
            onClose={() => setIsEventFormOpen(false)}
            onSubmit={handleEventSubmit}
          />
        )}
      </div>
    </Layout>
  );
}